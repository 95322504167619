/* Written by Ye Liu */

.MuiFab-primary {
    background-color: #536dfe !important;
}

.MuiSpeedDialAction-button {
    color: white !important;
}

.MuiTooltip-tooltip {
    background-color: #424242 !important;
}